import React from "react";

function Profil() {
  return (
    <div className="profil mb5">
      <h2 className="h2">Profil</h2>
      <p>
        Je passe mon temps à relever des défis à maintenant à plus de quarante
        ans, les victoires sont nombreuses, pourtant des changements pour donner
        le meilleur dans les entreprises qui m'ont reçu mettant en avant mes
        capacités nombreuses. Je suis un homme de la Technique, préférant mille
        actes aux milles paroles, du secteur du spatial et de l'aéronautique au
        transport, je fait avancer les entreprises. J'ai mis mes souhaits dans
        des actes au plus fort de mes compétences, bien sûr toujours entouré de
        femmes et d'hommes passionnés par le travail bien réalisé.
      </p>
    </div>
  );
}

export default Profil;
